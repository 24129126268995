// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-etno-cafe-jsx": () => import("./../../../src/pages/about-etno-cafe.jsx" /* webpackChunkName: "component---src-pages-about-etno-cafe-jsx" */),
  "component---src-pages-coffee-houses-jsx": () => import("./../../../src/pages/coffee-houses.jsx" /* webpackChunkName: "component---src-pages-coffee-houses-jsx" */),
  "component---src-pages-coffee-jsx": () => import("./../../../src/pages/coffee.jsx" /* webpackChunkName: "component---src-pages-coffee-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-for-business-jsx": () => import("./../../../src/pages/for-business.jsx" /* webpackChunkName: "component---src-pages-for-business-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-rules-jsx": () => import("./../../../src/pages/rules.jsx" /* webpackChunkName: "component---src-pages-rules-jsx" */),
  "component---src-templates-blog-jsx": () => import("./../../../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-coffee-house-jsx": () => import("./../../../src/templates/coffee-house.jsx" /* webpackChunkName: "component---src-templates-coffee-house-jsx" */),
  "component---src-templates-default-jsx": () => import("./../../../src/templates/default.jsx" /* webpackChunkName: "component---src-templates-default-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-tag-jsx": () => import("./../../../src/templates/tag.jsx" /* webpackChunkName: "component---src-templates-tag-jsx" */)
}

